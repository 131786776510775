import React, {useState} from "react";
import SearchData from "./searchData";



/**
 * A custom button component that toggles the visibility of a search bar.
 * @returns {JSX.Element} The rendered button component with associated search bar.
 */
export default function SearchBarComponent() {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen((isOpen) => !isOpen);
  }

  return (
    <div className="searchBar">
      <label htmlFor="search" className="block text-sm font-medium leading-6 text-gray-900">
        Search
      </label>
      {isOpen && <SearchData />}
      <button onClick={toggle}>
          <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
              <path d="M208 32a176 176 0 1 1 0 352 176 176 0 1 1 0-352zm0 384c51.7 0 99-18.8 135.3-50L484.7 507.3c6.2
              6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L366 343.3c31.2-36.4 50-83.7 50-135.3C416 93.1 322.9 0 208 0S0 93.1 0
              208S93.1 416 208 416zM192 304c0 8.8 7.2 16 16 16s16-7.2 16-16V224h80c8.8
              0 16-7.2 16-16s-7.2-16-16-16H224V112c0-8.8-7.2-16-16-16s-16
              7.2-16 16v80H112c-8.8 0-16 7.2-16 16s7.2 16 16 16h80v80z"
              />
          </svg>
      </button>
    </div>
  );
}
