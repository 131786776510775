import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import logo from '../../images/logo.jpg'


/**
 * Create a Bot component that renders a floating WhatsApp button
 * @component
 * @returns {JSX.Element} The rendered Bot component
 */
export default function Bot() {
  return (
    <div>

      {/* Floating WhatsApp button */}
      <FloatingWhatsApp 
		  phoneNumber="+18556246057" 
		  accountName='Harry from HSL Deals'
	       avatar= {logo}

	   />
    </div>
  );
};


