import React from 'react';

import { CheckCircleIcon } from '@heroicons/react/20/solid'

export default function Privacy() {
    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <p className="text-base font-semibold leading-7 text-indigo-600">Privacy Policy</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">HSL Deals</h1>
                <p className="mt-6 text-xl leading-8">
                    At HSL Deals, we are committed to protecting your privacy and ensuring the security of your personal information.
                    This Privacy Policy outlines how we collect, use, disclose, and safeguard your data when you visit our website
                    {' '}
                    <span>
                    <strong className="font-semibold text-gray-900">hsldeals.com </strong> or interact with our services. By using our website and services, you agree to the terms outlined in
                    this Privacy Policy.
                    </span>
                </p>
                <div className="mt-10 max-w-2xl">
                        <p className="text-base font-semibold leading-7 text-indigo-600">Information We Collect</p>
                        <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                    <strong className="font-semibold text-gray-900">Personal Information.</strong> When you visit our website, we may collect personally identifiable information such as your name,
                                    email address, and contact details when voluntarily provided by you.
                  </span>
                            </li>
                            <li className="flex gap-x-3">
                                <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                                <span>
                    <strong className="font-semibold text-gray-900">Non Personal Information.</strong> We may also collect non-personal information,
                                    such as browser type, IP address, and device information,
                                    to enhance our website's functionality and user experience.
                                </span>
                        </li>
                    </ul>
                </div>
                <div className="mt-10 max-w-2xl">
                    <p className="text-base font-semibold leading-7 text-indigo-600">How We Use Your Information</p>
                    <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                    <strong className="font-semibold text-gray-900">Provide and Improve Services.</strong> We use the information collected to deliver
                                and improve our products and services,
                                ensuring a personalized and efficient experience
                  </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                    <strong className="font-semibold text-gray-900">Communication.</strong> We may use your contact information
                                to respond to inquiries, provide updates, and inform you about our products, services, and promotions.
                  </span>
                        </li>
                        <li className="flex gap-x-3">
                            <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-indigo-600" aria-hidden="true" />
                            <span>
                    <strong className="font-semibold text-gray-900">Analytics.</strong> Non-personal information may be used for
                                analytics purposes to understand user behavior, preferences, and trends, helping us enhance our website and services.
                  </span>
                        </li>
                    </ul>
                </div>
                <br />
                <strong className="mt-8">By using our website, you consent to the terms of this Privacy Policy.</strong>
                {''} We reserve the right to update this Privacy Policy periodically. Any changes will be effective upon
                posting the revised policy on our website.
                <br />
                {''} If you have any questions or concerns about this Privacy Policy, please contact us at

                <p className="mt-4">
                    HSL Deals
                    <br />
                    <a
                        href="mailto:info@hslventures.com" className="font-semibold text-indigo-700">
                        info@hslventures.com

                    </a>
                </p>
            </div>
        </div>
    )
}


