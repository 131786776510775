import { Fragment, useState } from 'react'
import { Dialog, Popover, Transition } from '@headlessui/react'
import { ReactComponent as RunnerIcon } from '../images/runner.svg'
import { ReactComponent as ApparelIcon } from '../images/apparel.svg'
import { ReactComponent  as Accessories } from '../images/accessories.svg'
import { ReactComponent  as Cleated } from '../images/cleated.svg'
import { ReactComponent as Slack } from '../images/slack.svg'
import { ReactComponent as Returns } from '../images/returns.svg'
import { ReactComponent as HomeGoods } from '../images/home_goods.svg'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import logo from '../images/logo.jpg'
import MyButton from "./searchBarComponent";
import SearchBarComponent from "./searchBarComponent";
import Banner from "../components/banner"

  

const categories = [
    { name: 'Accessories', description: 'Training Equipment, Baseballs, Sunglasses', href: '/category/accessories', icon: Accessories },
    { name: 'Cleated Footwear', description: 'Football, Baseball, Track', href: '/category/cleated', icon: Cleated  },
    { name: 'Running Footwear', description: 'Basketball, Running, Training', href: '/category/athletic', icon: RunnerIcon },
    { name: 'Apparel', description: 'Hoodies, T-Shirts, Polos', href: '/category/apparel', icon: ApparelIcon },
    { name: 'Customer Returns', description: 'FBA, Retail, Marketplace customer returns', href: '/category/returns', icon: Returns },
    { name: 'Home Goods', description: 'Appliances, Kitchen Electronics, Furniture', href: '/category/home', icon: HomeGoods, isNew: true }

]

// const products = [
//     { name: 'Inventory Management', description: 'Let us help you dispose of excess inventory', href: '#', icon: ChartPieIcon },
//     { name: 'Engagement', description: 'Let us know how we can help sell your inventory', href: '#', icon: CursorArrowRaysIcon },
//     { name: 'Security', description: 'Your customers data will be safe and secure', href: '#', icon: FingerPrintIcon },
//     { name: 'Integrations', description: 'Connect our inventory api across other platforms', href: '#', icon: SquaresPlusIcon },
//     { name: 'Automations', description: 'Build a plan that gives you more time', href: '#', icon: ArrowPathIcon },
// ]

const callsToAction = [
    // { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
    { name: 'Chat on Slack', href: 'https://hsldeals.slack.com/archives/C050A2GKJP8', icon: Slack },
]

const company = [
    { name: 'About us', href: '/about', description: 'Learn more about our company values and mission to empower others' },
    { name: 'Careers', href: '/coming-soon', description: 'Looking for you next career opportunity? See all of our open positions' },
    {
        name: 'Support',
        href: 'https://hsldeals.slack.com/archives/C050A2GKJP8',
        description: 'Get in touch with our dedicated support team',
    },
    { name: 'Blogs', href: '/blogs', description: 'Read our latest announcements and get perspectives from our team' },
]



/**
 * Navbar component for the website header.
 *
 * @returns {JSX.Element} The generated JSX element representing the Navbar.
 */
export default function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    
    return (
        <header className="bg-white sticky top-0 z-10">
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
                <div className="flex lg:flex-1">
                    <a href="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Hsl Deals</span>
                        <img className="h-8 w-auto" src={logo} alt="" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12">
                    <Popover className="relative">
                        <Popover.Button
                            className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Products
                            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                <div className="p-4">
                                    {categories.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                        >
                                            <div
                                                className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-900"
                                                           aria-hidden="true"/>
                                                {item.isNew && <span
                                                    className="absolute top-0 right-0 inline-block px-1 py-0.5 bg-indigo-800 text-white text-xs font-semibold rounded">New Category</span>}
                                            </div>
                                            <div className="flex-auto">
                                                <a href={item.href} className="block font-semibold text-gray-900">
                                                    {item.name}
                                                    <span className="absolute inset-0"/>
                                                </a>
                                                <p className="mt-1 text-gray-600">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
                                    {callsToAction.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                                        >
                                            <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </Popover>

                    {/*<Popover className="relative">*/}
                    {/*     <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">*/}
                    {/*         Categories*/}
                    {/*         <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />*/}
                    {/*     </Popover.Button>*/}

                    {/*     <Transition*/}
                    {/*         as={Fragment}*/}
                    {/*         enter="transition ease-out duration-200"*/}
                    {/*         enterFrom="opacity-0 translate-y-1"*/}
                    {/*         enterTo="opacity-100 translate-y-0"*/}
                    {/*         leave="transition ease-in duration-150"*/}
                    {/*         leaveFrom="opacity-100 translate-y-0"*/}
                    {/*         leaveTo="opacity-0 translate-y-1"*/}
                    {/*     >*/}
                    {/*         <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">*/}
                    {/*             <div className="p-4">*/}
                    {/*                 {categories.map((item) => (*/}
                    {/*                     <div*/}
                    {/*                         key={item.name}*/}
                    {/*                         className="group relative flex gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"*/}
                    {/*                     >*/}
                    {/*                         <div className="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">*/}
                    {/*                             <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />*/}
                    {/*                         </div>*/}
                    {/*                         <div className="flex-auto">*/}
                    {/*                             <a href={item.href} className="block font-semibold text-gray-900">*/}
                    {/*                                 {item.name}*/}
                    {/*                                 <span className="absolute inset-0" />*/}
                    {/*                             </a>*/}
                    {/*                             <p className="mt-1 text-gray-600">{item.description}</p>*/}
                    {/*                         </div>*/}
                    {/*                     </div>*/}
                    {/*                 ))}*/}
                    {/*             </div>*/}
                    {/*         </Popover.Panel>*/}
                    {/*     </Transition>*/}
                    {/* </Popover>			*/}


                    <a href="/products" className="text-sm font-semibold leading-6 text-gray-900">
                        Collections
                    </a>
                    <a href="/coming-soon" className="text-sm font-semibold leading-6 text-gray-900">
                        Marketplace
                    </a>
                    <a href="/make-offer" type="button"
                       className="rounded px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                       style={{
                           background: 'linear-gradient(to right, #7f00ff, #e100ff)',
                       }}
                    >
                        Make an Offer!
                    </a>

                    <Popover className="relative">
                        <Popover.Button
                            className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                            Company
                            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true"/>
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className="absolute -left-8 top-full z-10 mt-3 w-96 rounded-3xl bg-white p-4 shadow-lg ring-1 ring-gray-900/5">
                                {company.map((item) => (
                                    <div key={item.name} className="relative rounded-lg p-4 hover:bg-gray-50">
                                        <a href={item.href}
                                           className="block text-sm font-semibold leading-6 text-gray-900">
                                            {item.name}
                                            <span className="absolute inset-0"/>
                                        </a>
                                        <p className="mt-1 text-sm leading-6 text-gray-600">{item.description}</p>
                                    </div>
                                ))}
                            </Popover.Panel>
                        </Transition>
                    </Popover>
                </Popover.Group>
                <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                    {/*<a href="#" className="text-sm font-semibold leading-6 text-gray-900">*/}
                    {/*    Log in <span aria-hidden="true">&rarr;</span>*/}
                    {/*</a>*/}
                    <MyButton/>
				
                </div>
            </nav>
            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 flex w-full flex-col justify-between overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="p-6">
                        <div className="flex items-center justify-between">
                            <a href="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Hsl Deals</span>
                                <img
                                    className="h-8 w-auto"
                                    src={logo}
                                    alt=""
                                />
                            </a>
                            <button
                                type="button"
                                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                        <div className="mt-6 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    {categories.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="group -mx-3 flex items-center gap-x-6 rounded-lg p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />

                                            </div>
                                            {item.name}

                                        </a>
                                    ))}
                                </div>
                                <div className="space-y-2 py-6">
                                    <a
                                        href="/products"
                                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Collections
                                    </a>
                                    <a
                                        href="/coming-soon"
                                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                    >
                                        Marketplace
                                    </a>
                                    <a href="/make-offer" type="button"
                                       className="rounded px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                       style={{
                                           background: 'linear-gradient(to right, #7f00ff, #e100ff)',
                                       }}
                                    >
                                        Make an Offer!
                                    </a>

                                    {company.map((item) => (
                                        <a
                                            key={item.name}
                                            href={item.href}
                                            className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                        >
                                            {item.name}
                                        </a>
                                    ))}
                                </div>
                                <div className="py-6">
                                    {/*       <a*/}
                                    {/*           href="#"*/}
                                    {/*           className="-mx-3 block rounded-lg py-2.5 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"*/}
                                    {/*>*/}
                                    {/*          Log in*/}
							 {/*</a>*/}
                                    <SearchBarComponent/>
							 

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky bottom-0 grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50 text-center">
                        {callsToAction.map((item) => (
                            <a
                                key={item.name}
                                href={item.href}
                                className="p-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-100"
                            >                                           <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />

                                {item.name}
                            </a>
                        ))}
                    </div>
                </Dialog.Panel>
            </Dialog>
            <Banner/>
        </header>
    )
}
