

/**
 * URL for the HSL Deals website.
 *
 * @type {string}
 */


// JavaScript file
const hslUrl = process.env.REACT_APP_HSL_URL;
const hslRedis = process.env.REACT_APP_HSL_REDIS;
// const hslMakeOfferServer = process.env.REACT_APP_MAKE_OFFER_SERVER_LOCAL;
const hslMakeOfferServer = process.env.REACT_APP_MAKE_OFFER_SERVER;
// const hslRedisTest = process.env.REACT_APP_HSL_REDIS_LOCAL;
export const GoogleTrackingId = process.env.REACT_APP_GOOGLE_TRACKING_ID;
// const baseURL = process.env.REACT_APP_BASEURL;


/**
 * Returns the URL for retrieving users data.
 *
 * @returns {string} The URL for retrieving users data.
 */
export const GetUsers = () => {
    return `${hslUrl}/users`;
}




/**
 * Retrieves the URL for the products with the given slug.
 *
 * @param {string} slug - The slug of the product.
 * @returns {string} - The URL for the products with the given slug.
 */
export const GetProductsBySlug = (slug) => {
    return `${hslUrl}/products/${slug}`;
};

export const GetProducts = () =>  {
    return `${hslUrl}/allproducts`;
};

// get products by id
export const GetId = () => {
    return  `${hslUrl}/products`;
}

// get trending items
export const GetTrends = () => {
    return `${hslUrl}/trending`;
}



/**
 * GetRedis is a function that returns a Redis endpoint URL.
 *
 * @returns {string} The Redis endpoint URL.
 */
export const GetRedis = () => {
    return `${hslRedis}/v1/api/json`;
}

export const GetPosts = () => {
    return `${hslRedis}/v1/api/posts`;
}



/**
 * Represents a server endpoint for making an offer.
 *
 * @class
 */
export const PostMakeOfferServer = () => {
    return `${hslMakeOfferServer}/send-offer`;
}