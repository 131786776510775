import {useEffect, useState} from "react";
import {GetId} from "../routes/routes";
import axios from 'axios';


/**
 * Represents a custom hook for retrieving product data.
 *
 * @returns {{ data: Array }} The product data.
 */
export const ProductHooks = (id) => {


    const [data, setData] = useState([]);
    // const { id } = useParams();

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await axios.get(GetId(), { params: { id } });
                setData(response.data);
                // console.log(response.data);
            } catch (error) {
                console.error("Error fetching data:", error);
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.error("Response data:", error.response.data);
                    // console.error("Response status:", error.response.status);
                    // console.error("Response headers:", error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // console.error("Request data:", error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    // console.error("Error message:", error.message);
                }
                // console.error("Config:", error.config);
            }
        };

        if (id) {
            getData();
        } else {
            // console.warn("No ID provided");
        }
    }, [id]);

    return { data };
};

