import ReactGA from "react-ga4";


/**
 * Function to create an analytics event tracker.
 *
 * @param {string} category - The category of the event. Default value is "Blog category".
 * @returns {function} - Returns the eventTracker function.
 */
const useAnalyticsEventTracker = (category="Blog category") => {
    return (action = "test action", label = "test label") => {
        ReactGA.event({category, action, label});
    };
}
export default useAnalyticsEventTracker;