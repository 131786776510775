import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Component} from "react";
import FrontPage from "./pages/frontPage";
import Navbar from "./components/navbar";
import ProductView from "./pages/productView";
import About from "./pages/about";
import ComingSoon from "./pages/comingSoon";
import ReactGA from 'react-ga4';
import Bot from './components/whatsApp/bot';
import Privacy from "./pages/privacy";
import Blogs from "./pages/blogs";

import DynamicGrid from "./components/dynamicGrid"
import {GoogleTrackingId} from "./routes/routes";
import NoPage from "./pages/404";
import MakeAnOfferPage from "./pages/makeAnOffer";
import {Toaster} from "react-hot-toast";


// OUR_TRACKING_ID
ReactGA.initialize(GoogleTrackingId);

class App extends Component {
  state = {
    data: null
  };


  /**
   * Renders the main application component.
   *
   * @returns {JSX.Element} The JSX element representing the main application component.
   */
  render() {
    return (
        <Router>
            <Toaster />
             <Navbar/>
          <Routes>
             <Route exact path='/' element={<FrontPage/>}/>
              <Route exact path='/404' element={<NoPage/>} />
              <Route exact path='/category/:name' element={<DynamicGrid/>} />
              <Route exact path='/products' element={<DynamicGrid/>}/>
              <Route exact path='/products/lot/:id' element={<ProductView/>}/>
              <Route path='/about' element={<About/>} />
              <Route path='/blogs' element={<Blogs/>} />
		     <Route path='/coming-soon' element={<ComingSoon/>} />
              <Route path='/make-offer' element={<MakeAnOfferPage/>} />
              <Route exact path='/privacy' element={<Privacy/>}/>
          </Routes>
          {/* Our bot component */}
		  <Bot />
          {/*<Footer />*/}
        </Router>
    );
  };

}
export default App;
