
import { Disclosure, Tab } from '@headlessui/react'
import {PhoneIcon} from '@heroicons/react/20/solid'
import {  MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import hero from '../images/hero.svg'
import {Navigate, useParams} from "react-router-dom";
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import {ProductHooks} from "../hooks/productHooks";
import { useState } from 'react';
import { BounceLoader } from 'react-spinners';
import howToMeasureImg from '../images/how_to_measure.jpg';
import sizeChartImg from '../images/size_chart.jpg';



const product = {

    images: [
        {
            id: 1,
            name: 'Angled view',
            // src: {hero},
            alt: 'Angled front view with bag zipped and handles upright.',
        },
        // More images...
    ],
    colors: [
        // { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
        // { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
        // { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
    ],

    details: [
        {
            name: 'Features',
            items: [
                'Contact sales for more details about this lot.'
            ],

        },
        // More sections...
    ],
    contact: [
        {
            name: 'Contact sales',
            href: 'https://hsldeals.slack.com/archives/C050A2GKJP8',
            icon: PhoneIcon
        }
    ]
}

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}



/**
 * Renders the view for a specific product.
 *
 * @param {Object} propData - The data of the product to be displayed.
 *
 * @return {JSX.Element} - The rendered product view.
 */
export default function ProductView({ data: propData }) {

    /**
     * Represents the status of loading a resource or performing an operation.
     *
     * The `loading` variable is used to indicate the state of a process. It can have one of the following values:
     *
     *  - `true` : Indicates that the resource is still being loaded or the operation is still in progress.
     *  - `false` : Indicates that the resource has finished loading or the operation has completed.
     *
     * Use the `loading` variable to check the status of a process in your code and determine the appropriate actions to take based on the current state.
     *
     * Note: The `loading` variable can be of any data type that represents a boolean value.
     *
     * @type {boolean}
     * @example
     * // Check if resource is still loading
     * if (loading) {
     *   // Display loading spinner
     *   showSpinner();
     * } else {
     *   // Resource has finished loading, perform further actions
     *   processResource();
     * }
     */
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const { data: hookData, error } = ProductHooks(id);
    const data = propData || hookData;

    const productId = propData ? propData.id : id;

    // console.log("Data in ProductView:", data);  // Log data for debugging
     // If the product doesn't exist, navigate to the 404 page
    if (error) return <Navigate to='/404' />;
    if (!data) return <div>Loading...</div>;


    return (
        <div className="bg-white">
            <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
                <div>
                    {/* go back to product page*/}
                    <a href="/products"
                       className="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block">
                        go back
                        <span aria-hidden="true"> &larr;</span>
                    </a>
                </div>
                <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">

                    {/* Image gallery */}
                    <Tab.Group as="div" className="flex flex-col-reverse">
                        {/* Image selector */}
                        <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none">
                            <Tab.List className="grid grid-cols-4 gap-6">
                                {product.images.map((image) => (
                                    <Tab
                                        key={image.id}
                                        className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                                    >
                                        {({selected}) => (
                                            <>
                                                <span className="sr-only"> {image.name} </span>
                                                <span className="absolute inset-0 overflow-hidden rounded-md">
                                                 {/*<img src={hero} alt="" className="h-full w-full object-cover object-center" />*/}
                                             </span>
                                                {/*<span*/}
                                                {/*    className={classNames(*/}
                                                {/*        selected ? 'ring-indigo-500' : 'ring-transparent',*/}
                                                {/*        'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'*/}
                                                {/*    )}*/}
                                                {/*    aria-hidden="true"*/}
                                                {/*/>*/}
                                            </>
                                        )}
                                    </Tab>
                                ))}
                            </Tab.List>
                        </div>

                        <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                            {product.images.map((image) => (
                                <Tab.Panel key={image.id}>
                                    <img
                                        src={hero}
                                        alt={image.alt}
                                        className="h-full w-full object-cover object-center sm:rounded-lg"
                                    />
                                </Tab.Panel>
                            ))}
                        </Tab.Panels>
                    </Tab.Group>

                    {/* Product info */}
                    <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">

                        <h1 className="text-3xl font-bold tracking-tight text-gray-900">{data.title}</h1>

                        <div className="mt-3">

                            <h2 className="sr-only">Product information</h2>
                            <p className="text-3xl tracking-tight text-gray-900">${data.price}</p>

                        </div>


                        {/* Reviews */}
                        {/*<div className="mt-3">*/}
                        {/*    <h3 className="sr-only">Reviews</h3>*/}
                        {/*    <div className="flex items-center">*/}
                        {/*        <div className="flex items-center">*/}
                        {/*            /!*{data.map((rating) => (*!/*/}
                        {/*                <StarIcon*/}
                        {/*                    // key={data.id}*/}
                        {/*                    className={classNames(*/}
                        {/*                        data.rating > rating ? 'text-indigo-500' : 'text-gray-300',*/}
                        {/*                        'h-5 w-5 flex-shrink-0'*/}
                        {/*                    )}*/}
                        {/*                    aria-hidden="true"*/}
                        {/*                />*/}
                        {/*            /!*))}*!/*/}
                        {/*        </div>*/}
                        {/*        <p className="sr-only">{data.rating} out of 5 stars</p>*/}
                        {/*    </div>*/}
                        {/*</div>*/}


                        <div className="mt-6">
                            <h3 className="sr-only">FOB</h3>
                            <div className="space-y-6 text-base text-gray-700">FOB {data.fob}</div>

                        </div>
                        <div className="mt-6">
                            <h3 className="sr-only">Avg Cost</h3>
                            <div className="space-y-6 text-base text-gray-700">Avg Cost Per Unit ${data.avg_cost}</div>
                        </div>
                        <div className="mt-6">
                            <h3 className="sr-only">Units</h3>
                            <div className="space-y-6 text-base text-gray-700">Total Units {data.totalUnits}</div>
                        </div>

                        <form className="mt-6" action={data.paymentUrl}>
                            {/* Colors */}
                            {/*    <div>*/}
                            {/*        /!*<h3 className="text-sm text-gray-600">Color</h3>*!/*/}

                            {/*        <RadioGroup value={selectedColor} onChange={setSelectedColor} className="mt-2">*/}
                            {/*            <RadioGroup.Label className="sr-only"> Choose a color </RadioGroup.Label>*/}
                            {/*            <span className="flex items-center space-x-3">*/}
                            {/*        {product.colors.map((color) => (*/}
                            {/*        <RadioGroup.Option*/}
                            {/*    key={color.name}*/}
                            {/*    value={color}*/}
                            {/*    className={({ active, checked }) =>*/}
                            {/*        classNames(*/}
                            {/*            color.selectedColor,*/}
                            {/*            active && checked ? 'ring ring-offset-1' : '',*/}
                            {/*            !active && checked ? 'ring-2' : '',*/}
                            {/*            'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'*/}
                            {/*        )*/}
                            {/*    }*/}
                            {/*>*/}
                            {/*    <RadioGroup.Label as="span" className="sr-only">*/}
                            {/*        {' '}*/}
                            {/*        {color.name}{' '}*/}
                            {/*    </RadioGroup.Label>*/}
                            {/*    <span*/}
                            {/*        aria-hidden="true"*/}
                            {/*        className={classNames(*/}
                            {/*            color.bgColor,*/}
                            {/*            'h-8 w-8 rounded-full border border-black border-opacity-10'*/}
                            {/*        )}*/}
                            {/*                />*/}
                            {/*            </RadioGroup.Option>*/}
                            {/*        ))}*/}
                            {/*      </span>*/}
                            {/*        </RadioGroup>*/}
                            {/*    </div>*/}

                            <div className="sm:flex-col1 mt-10 flex">

                                <button

                                    type="submit"
                                    className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-8 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                                >
                                    Purchase Now
                                </button>

                                {/*<button*/}
                                {/*    type="button"*/}
                                {/*    className="ml-4 flex items-center justify-center rounded-md py-3 px-3 text-gray-400 hover:bg-gray-100 hover:text-gray-500"*/}
                                {/*>*/}
                                {/*    <HeartIcon className="h-6 w-6 flex-shrink-0" aria-hidden="true" />*/}
                                {/*    <span className="sr-only">Add to favorites</span>*/}
                                {/*</button>*/}
                            </div>
                        </form>
                        <br/>
                        {/*our image disclaimer*/}
                        {/*show this disclaimer for all lots except lot 36*/}
                        {parseInt(productId, 10) !== 36 &&
                            parseInt(productId, 10) !== 55 &&
                        <div className="rounded-md bg-yellow-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                                </div>
                                <div className="ml-3">
                                    <h3 className="text-sm font-medium text-yellow-800">Image Disclaimer</h3>
                                    <div className="mt-2 text-sm text-yellow-700">
                                        <p>
                                            Some of the product images on this website might not be an exact
                                            representation of the product being sold.
                                            Images are for illustrative purposes only, and actual products may vary in
                                            appearance, color, or other attributes. Please refer to the product
                                            description and specifications for accurate details about the items you are
                                            interested in purchasing.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <br></br>
                        {/*this is our furniture grader. it only shows if the item is furniture.*/}
                        {/*// Change 'specificProductId' to the ID of your specific product*/}
                        {parseInt(productId, 10) === 36 &&
                            <div className="rounded-md bg-yellow-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">

                                        <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-bold text-yellow-800">Furniture Grading Disclaimer</h3>
                                        <div className="mt-2 text-sm text-yellow-700">
                                            <div className="mt-4">
                                                <p>Grade A: Excellent condition with no defects. May have been displayed but
                                                    not used.</p>
                                                <p>Grade B: Good condition, minor defects or cosmetic damage. Fully
                                                    functional.</p>
                                                <p>Grade C: Moderate wear or visible defects. Functional but with noticeable
                                                    damage.</p>
                                                <p>Grade D: Significant wear or damage. May require substantial repairs.
                                                    Sold as-is.</p>
                                                <p>All sales are final for items graded C or D. Please review the product's
                                                    condition carefully before purchasing.</p>
                                                <br></br>
                                                <p className="text-sm font-bold text-yellow-800">All Products can be inspected at the
                                                    Los Angeles, CA warehouse.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/*Show this disclaimer only for costume gallery lot 55*/}
                        {parseInt(productId, 10) === 55 &&
                            <div className="rounded-md bg-indigo-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <ExclamationTriangleIcon className="h-5 w-5 text-indigo-400" aria-hidden="true"/>
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-indigo-800">Helpful sizing information</h3>
                                        <div className="mt-2 text-sm text-yellow-700 flex space-x-2">
                                            <div className="group">
                                                <a href={howToMeasureImg} download="how_to_measure.jpg">
                                                    <img src={howToMeasureImg} alt="How to Measure" className="w-64 h-auto transform transition-transform duration-300 group-hover:scale-150" />
                                                </a>
                                            </div>
                                            <div className="group">
                                                <a href={sizeChartImg} download="size_chart.jpg">
                                                    <img src={sizeChartImg} alt="Size Chart" className="w-64 h-auto transform transition-transform duration-300 group-hover:scale-150" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/*    addition detail and features section*/}
                        <section aria-labelledby="details-heading" className="mt-12">
                            <h2 id="details-heading" className="sr-only">
                                Additional details
                            </h2>

                            <div className="divide-y divide-gray-200 border-t">
                                {product.details.map((detail) => (
                                    <Disclosure as="div" key={detail.name}>
                                        {({open}) => (
                                            <>
                                                <h3>
                                                    <Disclosure.Button
                                                        className="group relative flex w-full items-center justify-between py-6 text-left">
                                        <span
                                            className={classNames(open ? 'text-indigo-600' : 'text-gray-900', 'text-sm font-medium')}
                                        >
                                          {detail.name}
                                        </span>
                                                        <span className="ml-6 flex items-center">
                                              {open ? (
                                                  <MinusIcon
                                                      className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                                      aria-hidden="true"
                                                  />
                                              ) : (
                                                  <PlusIcon
                                                      className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                                      aria-hidden="true"
                                                  />
                                              )}
                                            </span>
                                                    </Disclosure.Button>
                                                </h3>
                                                <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                                                    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                                    <ul role="list">
                                                        {detail.items.map((item) => (
                                                            <li key={item}>{item}</li>
                                                        ))}
                                                    </ul>
                                                    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                                    <ul role="list">

                                                        <li>{product.contact.name}</li>

                                                    </ul>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                ))}
                            </div>
                        </section>

                    </div>
                </div>

                {/*this is airtable iframe*/}
                <div>
                    {loading && (
                        <div className="iframe-loading">
                            <BounceLoader loading={loading} size={30} color="purple"/>
                            <p>Loading...</p>
                        </div>
                    )}
                    {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
                    <iframe
                        className="airtable-embed"
                        src={data.csvUrl}
                        width="100%"
                        height="533"
                        onLoad={() => setLoading(false)}
                        // lazy loading for ux improvement
                        loading="lazy"
                    ></iframe>
                </div>
            </div>
        </div>
    )
}
