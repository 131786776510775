
import React, { useRef } from 'react';

/**
 * FAQs
 * @type {Array<Object>}
 */
const faqs = [
  {
    id: 1,
    question: "How do you process payments?",
    answer:
      "\n" +
        "We prioritize your payment security by utilizing Stripe, a secure gateway, for all transactions. Your payment details are encrypted, never stored on our servers, and processed through industry-leading security measures, ensuring a safe and protected transaction experience.",
  },
  {
    id: 2,
    question: "What is your shipping and returns policy?",
    answer: "Customers are responsible for the cost of shipping. In the event of returns or shipping errors, corrections will be made upon receipt of a verified receiving report. We strive to ensure a smooth and satisfactory resolution to any shipping-related concerns.",
  },
  {
    id: 3,
    question: "Is HSL Deals actively looking to buy more products?",
    answer: "At HSLDeals, we are always open to discovering new and exciting products. Feel free to send your product offers to the email address above. We look forward to exploring potential collaborations and expanding our product range.",
  },
  // More questions...
]



/**
 * Scroll to section within the FAQ component.
 *
 * @return {JSX.Element} The FAQ component.
 */
export default function Faq() {
  // Step 1: Create a ref for the section you want to scroll to
  const scrollToSectionRef = useRef(null);

  // Step 2: Create a function to scroll to the section
  // eslint-disable-next-line no-unused-vars
  const scrollToSection = () => {
    scrollToSectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
      <div className="bg-gray-50">
        <div className="mx-auto max-w-7xl px-6 py-16 sm:py-24 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Have a different question and can’t find the answer you’re looking for? Reach out to our support team by{' '}
              {/* Step 3: Update the link to call the scroll function */}
              <a href="mailto:shipping@hslventures.com" className="font-semibold text-indigo-600 hover:text-indigo-500">
                sending us an email
              </a>{' '}
              and we’ll get back to you as soon as we can.
            </p>
          </div>
          <div className="mt-20" id="frequentlyAskedQuestions">
            {/* Step 1: Add the ref to the section you want to scroll to */}
            <div ref={scrollToSectionRef} id="scrollToSection">
              {/* Step 2: The content of the section */}
              <dl className="space-y-16 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-16 sm:space-y-0 lg:gap-x-10">
                {faqs.map((faq) => (
                    <div key={faq.id}>
                      <dt className="text-base font-semibold leading-7 text-gray-900">{faq.question}</dt>
                      <dd className="mt-2 text-base leading-7 text-gray-600">{faq.answer}</dd>
                    </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
  );
}


