        import { useEffect, useState, useCallback } from 'react';
        import axios from 'axios';



        /**
         * Fetches data from a given URL using axios.
         *
         * @param {string} url - The URL to fetch data from.
         * @returns {Object} - An object containing the fetched data, loading status, and error.
         */

        export const useFetchData = (url) => {
            const [data, setData] = useState(null);
            const [isLoading, setIsLoading] = useState(true);
            const [error, setError] = useState(null);

            const fetchData = useCallback(async () => {
                setIsLoading(true);
                try {
                    const response = await axios.get(url);
                    // console.log('Response:', response);
                    setData(response.data);
                    setIsLoading(false);
                } catch (error) {
                    setError(error);
                }
            }, [url]);

            useEffect(() => {
                fetchData();
            }, [fetchData]);
            // console.log('Data:', data);  // New logging here
            return { data, isLoading, error };
        };