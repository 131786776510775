


/**
 * Sorts an array of products based on their status.
 *
 * @param {Object[]} data - The array of products.
 * @param {string} data[].status - The status of the product (Stocked, Withdraw, Sold).
 * @returns {Object[]} - The sorted array of products.
 */
export const sortProducts = (data) => {
    const sortOrder = {
        Stocked: 1,
        Withdraw: 2,
        Sold: 3,
    }

    return data.sort((a, b) => {
        return sortOrder[a.status] - sortOrder[b.status];
    });
}