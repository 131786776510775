
import logo from '../images/logo.jpg'
import hero_hsldeals from '../images/hero_hsldeals1.png'
import useAnalyticsEventTracker from '../components/eventTracker';
import HeroIcon from '../components/heroIcon';
import {CursorArrowRaysIcon} from '@heroicons/react/24/outline';
import Faq from '../components/faq';
import DynamicGrid from "../components/dynamicGrid";
import {UserEmail} from "../hooks/emailHooks";


const hero = [
    { name: '', icon: CursorArrowRaysIcon }
]

const perks = [
    {
        name: 'Purchase Returns',
        imageUrl: 'https://tailwindui.com/plus-assets/img/ecommerce/icons/icon-returns-light.svg',
        description: 'We also have customer returns. Ask about our inventory',
    },
    {
        name: 'Global Delivery',
        imageUrl: 'https://tailwindui.com/plus-assets/img/ecommerce/icons/icon-calendar-light.svg',
        description:
            'We offer a global delivery service. Checkout today and receive your products ASAP',
    },
    {
        name: 'Easy Payment',
        imageUrl: 'https://tailwindui.com/plus-assets/img/ecommerce/icons/icon-gift-card-light.svg',
        description: 'Looking for a deal? You can trust all our processing through our Stripe payment gateway',
    },
    {
        name: 'For the Planet',
        imageUrl: 'https://tailwindui.com/plus-assets/img/ecommerce/icons/icon-planet-light.svg',
        description: 'We repurpose and recycle inventory. Less production is a net benefit for the planet ',
    },
]
const footerNavigation = {
    products: [
        { name: 'Running Footwear', href: '/category/athletic' },
        { name: 'Apparel', href: '/category/apparel' },
        { name: 'Cleated Footwear', href: '/category/cleated' },
        { name: 'Home Goods', href: '/category/home' },
        { name: 'Customer Returns', href: '/category/returns' },
        { name: 'Accessories', href: '/category/accessories' },
    ],
    company: [
        { name: 'Who we are', href: '/about' },
        { name: 'Blogs', href: '/blogs' },
        { name: 'Careers', href: '/coming-soon' },
        { name: 'Privacy', href: '/privacy' },
    ],
    customerService: [
        { name: 'Contact', href: 'https://hsldeals.slack.com/archives/C050A2GKJP8' },
        { name: 'Shipping', href: '#frequentlyAskedQuestions' },
        { name: 'Returns', href: '#frequentlyAskedQuestions' },
        { name: 'Secure Payments', href: '#frequentlyAskedQuestions' },
        { name: 'FAQ', href: '#frequentlyAskedQuestions' },

    ],
}

const navigation = [
    {
        name: 'Linkedin',
        href: 'https://www.linkedin.com/company/hsl-deals/',
        icon: (props) => (
            <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"{...props}>
                <path
                    fillRule="evenodd"
                    d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },
    {
        name: 'Instagram',
        href: '#',
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                />
            </svg>
        ),
    },
    {
        name: 'X',
        href: '#',
        icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
                <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
            </svg>
        ),
    },
]



/**
 * Renders the front page of the application.
 *
 * @returns {JSX.Element} The front page component
 */
export default function FrontPage() {

    /**
     * Represents an email address.
     *
     * @typedef {string} email
     * @description An email address should be a string that adheres to the standard email address format:
     *  - It must contain a single @ character, which separates the local part from the domain part.
     *  - The local part should consist of one or more alphanumeric characters, dots, underscores, or hyphens.
     *  - The domain part should consist of one or more alphanumeric characters, dots or hyphens, followed by a dot and a valid top-level domain (TLD) such as .com, .net, or .org.
     *
     * @example
     * const validEmail = 'test@example.com';
     * const invalidEmail = 'testexample.com';
     *
     * @see https://www.ietf.org/rfc/rfc5322.txt
     */
    const { email, msg, setEmail, CreateAccount } = UserEmail();

    // Google Analytics tracker
    const gaEventTracker = useAnalyticsEventTracker('FrontPage');


    return (
        <div className="bg-white">
                {/* Hero section */}
                    <div className="relative isolate overflow-hidden bg-gradient-to-b from-indigo-100/20">
                        <div className="mx-auto max-w-7xl pt-10 pb-24 sm:pb-32 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:py-40 lg:px-8">
                            <div className="px-6 lg:px-0 lg:pt-4">
                                <div className="mx-auto max-w-2xl">
                                    <div className="max-w-lg">

                                        <div className="mt-24 sm:mt-32 lg:mt-16">
                                            <a href="/products" onClick={()=>gaEventTracker('products')} className="inline-flex space-x-6">
                                        <span className="rounded-full bg-indigo-600/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-inset ring-indigo-600/10">
                                          New Arrivals
                                        </span>
	                                     <div>
										  {hero.map((category, index) => (
                                            <HeroIcon key={index} icon={category.icon} name={category.name} />
                                        ))}
									   </div>
                                            </a>
                                        </div>
                                        <h1 className="mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                            Supercharge Your Inventory
                                        </h1>
                                        <p className="mt-6 text-lg leading-8 text-gray-700">
                                            <strong>HSL Deals</strong> offers unbeatable deals on top-quality inventory.
                                        </p>
                                        <div className="mt-10 flex items-center gap-x-6">
                                            <a
                                                href="/products"
                                                className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Collections
                                            </a>
                                    {/*Download our csv file from airtable api*/}
								    <a href= "https://hsldeals.com:4242/v1/airtable/csv" className="text-sm font-semibold leading-6 text-gray-900">
                                                view on csv <span aria-hidden="true">→</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-20 sm:mt-24 md:mx-auto md:max-w-2xl lg:mx-0 lg:mt-0 lg:w-screen">
                                <div
                                    className="absolute inset-y-0 right-1/2 -z-10 -mr-10 w-[200%] skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 md:-mr-20 lg:-mr-36"
                                    aria-hidden="true"
                                />
                                <div className="shadow-lg md:rounded-3xl">
                                    <div className="bg-indigo-500 [clip-path:inset(0)] md:[clip-path:inset(0_round_theme(borderRadius.3xl))]">
                                        <div
                                            className="absolute -inset-y-px left-1/2 -z-10 ml-10 w-[200%] skew-x-[-30deg] bg-indigo-100 opacity-20 ring-1 ring-inset ring-white md:ml-20 lg:ml-36"
                                            aria-hidden="true"
                                        />
                                        <div className="relative px-6 pt-8 sm:pt-16 md:pl-16 md:pr-0">
                                            <div className="mx-auto max-w-2xl md:mx-0 md:max-w-none">
                                                <div className="w-screen overflow-hidden rounded-tl-xl bg-gray-900">

                                                    <img
                                                        src={hero_hsldeals}
                                                        alt=""
                                                        className="h-[350px] w-auto"
                                                    />
                                                </div>
                                            </div>
                                            <div
                                                className="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 md:rounded-3xl"
                                                aria-hidden="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
                    </div>

                <section aria-labelledby="trending-heading">
                    <div className="mx-auto max-w-7xl py-24 px-4 sm:px-6 sm:py-32 lg:px-8 lg:pt-32">
                        <div className="md:flex md:items-center md:justify-between">
                            <h2 id="favorites-heading" className="text-2xl font-bold tracking-tight text-gray-900">
                                Trending Products
                            </h2>
                            <a href="/products" className="hidden text-sm font-medium text-indigo-600 hover:text-indigo-500 md:block">
                                See everything
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                        {/*<br></br>*/}
                        <div className="mt-8 text-sm md:hidden">
                            <a href="/products" className="font-medium text-indigo-600 hover:text-indigo-500">
						  See everything
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                        <br></br>
                        {/*here is the trending component and dynamic data*/}
                        <DynamicGrid/>
                    </div>
                </section>
		  
                <section aria-labelledby="perks-heading" className="border-t border-gray-200 bg-gray-50">
                    <h2 id="perks-heading" className="sr-only">
                        Our perks
                    </h2>

                    <div className="mx-auto max-w-7xl py-24 px-4 sm:px-6 sm:py-32 lg:px-8">
                        <div className="grid grid-cols-1 gap-y-12 sm:grid-cols-2 sm:gap-x-6 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-0">
                            {perks.map((perk) => (
                                <div
                                    key={perk.name}
                                    className="text-center md:flex md:items-start md:text-left lg:block lg:text-center"
                                >
                                    <div className="md:flex-shrink-0">
                                        <div className="flow-root">
                                            <img className="-my-1 mx-auto h-24 w-auto" src={perk.imageUrl} alt="" />
                                        </div>
                                    </div>
                                    <div className="mt-6 md:mt-0 md:ml-4 lg:mt-6 lg:ml-0">
                                        <h3 className="text-base font-medium text-gray-900">{perk.name}</h3>
                                        <p className="mt-3 text-sm text-gray-500">{perk.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                 {/*here is the faq section */}
                 <Faq />

            <footer aria-labelledby="footer-heading" className="bg-gray-50">
                <h2 id="footer-heading" className="sr-only">
                    Footer
                </h2>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="border-t border-gray-200 py-20">
                        <div className="grid grid-cols-1 md:grid-flow-col md:auto-rows-min md:grid-cols-12 md:gap-x-8 md:gap-y-16">
                            {/* Image section */}
                            <div className="col-span-1 md:col-span-2 lg:col-start-1 lg:row-start-1">
                                <img
                                    src={logo}
                                    alt=""
                                    className="h-8 w-auto"
                                />
                            </div>

                            {/* Sitemap sections */}
                            <div className="col-span-6 mt-10 grid grid-cols-2 gap-8 sm:grid-cols-3 md:col-span-8 md:col-start-3 md:row-start-1 md:mt-0 lg:col-span-6 lg:col-start-2">
                                <div className="grid grid-cols-1 gap-y-12 sm:col-span-2 sm:grid-cols-2 sm:gap-x-8">
                                    <div>
                                        <h3 className="text-sm font-medium text-gray-900">Products</h3>
                                        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                        <ul role="list" className="mt-6 space-y-6">
                                            {footerNavigation.products.map((item) => (
                                                <li key={item.name} className="text-sm">
                                                    <a href={item.href} className="text-gray-500 hover:text-gray-600">
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div>
                                        <h3 className="text-sm font-medium text-gray-900">Company</h3>
                                        {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                        <ul role="list" className="mt-6 space-y-6">
                                            {footerNavigation.company.map((item) => (
                                                <li key={item.name} className="text-sm">
                                                    <a href={item.href} className="text-gray-500 hover:text-gray-600">
                                                        {item.name}
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div>
                                    <h3 className="text-sm font-medium text-gray-900">Customer Service</h3>
                                    {/* eslint-disable-next-line jsx-a11y/no-redundant-roles */}
                                    <ul role="list" className="mt-6 space-y-6">
                                        {footerNavigation.customerService.map((item) => (
                                            <li key={item.name} className="text-sm">
                                                <a href={item.href} className="text-gray-500 hover:text-gray-600">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                            {/* Newsletter section */}
                            <div className="mt-12 md:col-span-8 md:col-start-3 md:row-start-2 md:mt-0 lg:col-span-4 lg:col-start-9 lg:row-start-1">
                                <h3 className="text-sm font-medium text-gray-900">Sign up for our email notifications</h3>
                                <p className="mt-6 text-sm text-gray-500">The latest deals and savings, sent to your inbox weekly.</p>
                                <p className="text-sm font-medium text-red-800">{msg}</p>
                                <form className="mt-2 flex sm:max-w-md" onSubmit={CreateAccount} method="POST">
                                    <label htmlFor="email-address" className="sr-only">
                                        Email address
                                    </label>
                                    <input
                                        id="email-address"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        type="text"
                                        autoComplete="email"
                                        required
                                        className="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white py-2 px-4 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                                    />
                                    <div className="ml-4 flex-shrink-0">
                                        <button
                                            type="submit"
                                            className="flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        >
                                            Sign up
                                        </button>
                                    </div>
                                </form>
                                <p className="mt-4 text-sm leading-6 text-gray-500">
                                    We care about your data. Read our{' '}
                                    <a href="/privacy" className="font-semibold text-indigo-700">
                                        privacy&nbsp;policy
                                    </a>
                                    .
                                </p>
                                {/*our toaster message is used when a new person joins.*/}
                                {/*<Toaster/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center space-x-6 md:order-2">
                        {navigation.map((item) => (
                            <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">{item.name}</span>
                                <item.icon className="h-6 w-6" aria-hidden="true" />
                            </a>
                        ))}
                    </div>
                    <br />
                    <div className="border-t border-gray-100 py-10 text-center">
                        <p className="text-sm text-gray-500">&copy; {new Date().getFullYear()} HSL Ventures, Inc. All rights reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    )
}
